import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Få din egen bedrift synlig, nå ut til brukere og få flere kunder. Med
markedsføring på Trailguide får alle som planlegger en ferie den nødvendige
informasjonen de trenger om overnatting, sykkelutleie, guidede turer,
heis/shuttle, reparasjoner eller bare noe kaldt å drikke etter en bra dag
på sykkelen.`}</strong>{` Click `}<a parentName="p" {...{
        "href": "https://www.trailguide.no/news/adsforlocalservices"
      }}>{`here`}</a>{`
for the English version.`}</p>
    <br />
    <Image src="webapp/screenshot_money.jpg" mdxType="Image" />
    <ul>
      <li parentName="ul">{`Fremmer relevante aktører opp til en radius på 30km`}</li>
      <li parentName="ul">{`Er synlig i alle stibeskrivelsene`}</li>
      <li parentName="ul">{`Firmaet ditt er synlig som et interessepunkt`}</li>
      <li parentName="ul">{`Automatisk oppdatert informasjon fra Facebook`}</li>
    </ul>
    <br />
    <Image src="news/localservicesmapclose2.jpg" mdxType="Image" />
    <ul>
      <li parentName="ul">{`Visning av nettsiden, facebooksiden, e-post, telefon og lokasjon`}</li>
      <li parentName="ul">{`Eget ikon for visning av bedriften`}</li>
      <li parentName="ul">{`Henvis til Trailguide hvis kunder spør om gode sykkelstier`}</li>
      <li parentName="ul">{`Et interaktivt kart kan bli integrert i nettsiden deres`}</li>
    </ul>
    <br />
    <Image src="news/facebookwebsite.jpg" mdxType="Image" />
    <p>{`All nødvendig informasjon blir `}<strong parentName="p">{`automatisk importert`}</strong>{` fra Facebooksiden
deres. Det betyr at du ikke trenger å bekymre deg for å oppdatere noe info
på Trailguide, hvis den er oppdatert på Facebook er den oppdatert på Trailguide!!`}</p>
    <br />
    <Image src="news/trailguidephone1.jpg" mdxType="Image" />
    <p>{`Trailguide er en gratis web-app. Vi tilbyr annonser for sykkel- og
turistrelaterte bedrifter for å finansiere oss, og tilbyr samtidig verdifull
informasjon for kundene. Lokale aktører tjener på `}<strong parentName="p">{`flere kunder`}</strong>{`, og
Trailguide forblir gratis.`}</p>
    <br />
    <Image src="webapp/earnmoney.jpg" mdxType="Image" />
    <p>{`Promoteringen tilbys mot en årlig avgift. `}<Link to="/about" mdxType="Link">{`Finn kontaktinformasjon her.`}</Link></p>
    <p>{`Eller gå direkte til `}<a href="https://trailguide.net/order?lang=no">{`ordreskjema.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      